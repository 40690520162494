<template>
  <div class="left-check-box" @click="() => onClick()">
    <div class="icon-wrap ui-mr-1">
      <icon class="img" :src="`img/common/check.png`" width="3.5rem" height="7rem" :cover="false"/>
      <icon v-if="checked" class="img" :src="`img/common/check-on.png`" width="3.5rem" height="7rem" :cover="false"/>
    </div>
    <div class="text">{{text}}</div>
  </div>
</template>

<script>
import Icon from '@/components/common/Icon'

export default {
  components: {
    Icon,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      required: true,
    },
  },
  watch: {
    value(val) {
      this.checked = val
    }
  },
  data() {
    return {
      checked: false
    }
  },
  beforeMount() {
    if (this.value) {
      this.checked = this.value
    }
  },
  methods: {
    onClick() {
      let checked = !this.checked
      this.$emit('click', {
        checked,
        update() {
          this.checked = checked
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.left-check-box {
  overflow: hidden;
  color: #000;
  font-size: 2rem;
  font-weight: 400;
  .icon-wrap {
    float: left;
    position: relative;
    width: 3.5rem;
    height: 7rem;
    .img {
      position: absolute;
      top:0;
      left:0;
    }
  }
  > .img,
  > .text {
    float: left;
    line-height: 7.3rem;
    height: 7rem;
  }
}
</style>
