var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "left-check-box",
      on: {
        click: function() {
          return _vm.onClick()
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "icon-wrap ui-mr-1" },
        [
          _c("icon", {
            staticClass: "img",
            attrs: {
              src: "img/common/check.png",
              width: "3.5rem",
              height: "7rem",
              cover: false
            }
          }),
          _vm.checked
            ? _c("icon", {
                staticClass: "img",
                attrs: {
                  src: "img/common/check-on.png",
                  width: "3.5rem",
                  height: "7rem",
                  cover: false
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c("div", { staticClass: "text" }, [_vm._v(_vm._s(_vm.text))])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }