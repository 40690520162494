<template>
    <div class="container">
        <page-header :title="`거절하는 이유를\n선택해주세요.`" />

        <template v-for="(row, key) in reasons">
            <left-check-box
                :key="key"
                :text="row"
                :value="row === reason"
                @click="result => (reason = row)"
                />
        </template>

        <footer-box
            submit-text="답변 보내기"
            :submit-cb="() => rejectOffer()"
            :submit-disabled="!valid"
            />
    </div>
</template>

<script>
import PageHeader from '@/components/common/PageHeader'
import LeftCheckBox from '@/components/common/LeftCheckBox'
import FooterBox from '@/components/common/FooterBox'

export default {
    name: 'RejectOffer',
    components: {
        PageHeader,
        LeftCheckBox,
        FooterBox,
    },
    data() {
        return {
            reason: '',
            reasons: [
                '같은 날 다른 일정이 있어 어렵습니다.',
                '금액이 맞지 않아 어렵습니다.',
                '개인 사정이 있어 어려울 것 같습니다.',
                '너무 먼 행사라 지금은 예약할 수 없어요.',
            ],
            que: {
                rejectOffer: false,
            },
        }
    },
    computed: {
        offerUid() {
            return this.$route.params?.offerUid
        },
        valid() {
            return !this.que.rejectOffer && Boolean(this.reason && this.offerUid)
        },
    },
    methods: {
        rejectOffer() {
            if (!this.valid) return
            if (this.que.rejectOffer) return
            this.que.rejectOffer = true

            const req = {
                method: 'put',
                url: `/artist/offerPro/${this.offerUid}/reject`,
                data: {
                    bodyText: this.reason,
                },
            }

            this.$http(req)
                .then(({ data }) => {
                    log(...Object.values(req), data)

                    const resultData = this.$lib.resultCheck(data.resultData)

                    if (resultData.success) {
                        this.$router.back()
                    } else {
                        this.$alert(resultData)
                    }
                })
                .catch(log.error)
                .finally(() => {
                    this.que.rejectOffer = false
                })
        },
    },
}
</script>
