var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("page-header", { attrs: { title: "거절하는 이유를\n선택해주세요." } }),
      _vm._l(_vm.reasons, function(row, key) {
        return [
          _c("left-check-box", {
            key: key,
            attrs: { text: row, value: row === _vm.reason },
            on: {
              click: function(result) {
                return (_vm.reason = row)
              }
            }
          })
        ]
      }),
      _c("footer-box", {
        attrs: {
          "submit-text": "답변 보내기",
          "submit-cb": function() {
            return _vm.rejectOffer()
          },
          "submit-disabled": !_vm.valid
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }